export default () => {
  return {
    meta: {
      title: '',
      description: ''
    },

    content: {}
  }
}
